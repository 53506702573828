<template>
  <div class="column is-12 p-0">
    <div class="columns mt-0">
      <div class="column p-0 is-full-mobile is-one-quarter-tablet is-3-desktop">
        <b-field class="text-left" label="Filtrar por">
          <b-select
            placeholder="Selecciona un filtro"
            v-model="filtroActivo"
            expanded
          >
            <option
              v-for="(filtro, key, index) in filtros"
              :value="key"
              :key="index"
              >{{ filtro }}</option
            >
          </b-select>
        </b-field>
      </div>
      <div
        class="column is-one-quarter-tablet is-3-desktop py-0"
        v-if="filtroActivo && filtroActivo != 'todas'"
      >
        <b-field class="text-left" :label="nombreFiltro">
          <b-autocomplete
            v-if="autocomplete && !id"
            v-model="opcionActiva"
            placeholder="Buscar..."
            class="capitalize"
            :keep-first="true"
            :field="field"
            :grouped="grouped"
            :open-on-focus="true"
            :data="filteredOpciones"
            @select="cargarLineas"
          />
          <valid-select
            v-if="!autocomplete && !id"
            grouped
            placeholder="Selecciona una opción..."
            v-model="opcionActiva"
            :data="filteredOpciones"
            show="key"
            expanded
            @directInput="cargarLineas"
            realValue="key"
          ></valid-select>
          <b-input v-if="id" type="number" v-model="opcionActiva" />
        </b-field>
      </div>
    </div>
    <Tags
      :tagsFiltros="tagsFiltros"
      :pasiva="tipoLineas"
      @removeFilter="removeFilter"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tags from "@/components/generals/TagsFiltros";
import ValidSelect from "@/components/form/ValidSelect";
import { debounce } from "@/vendors/helpers";

export default {
  name: "FiltrosLinea",
  props: {
    pagina: Number,
    tipoLineas: Boolean,
    lineaStatus: Array,
    ejecutivos: Array
  },
  components: {
    Tags,
    ValidSelect
  },
  data() {
    return {
      filtroActivo: null,
      filtros: {
        todas: "Todas",
        status_id: "Estatus",
        personas_id: "Ejecutivo",
        persona2_id: "Acreditado",
        id: "Folio",
        productos_financieros_id: "Producto Financiero"
      },
      tagsFiltros: [],
      opcionActiva: "",
      opciones: [],
      sinFiltro: true,
      field: null,
      grouped: false,
      autocomplete: true,
      id: false
    };
  },
  methods: {
    /*
      Se agrega la opción al filtro y se llama a la API con el nuevo filtro
      @opcion(Object): nuevo filtro seleccionado
    */
    cargarLineas(opcion) {
      if (opcion) {
        let filtro = this.setFiltro(opcion);
        let repetido = this.checkRepetido(filtro);
        if (!repetido) {
          this.tagsFiltros.push(filtro);
          this.$emit('toggleDropdownReestructura', this.filtros[this.filtroActivo] === 'Acreditado');
          if (this.filtros[this.filtroActivo] === 'Acreditado') {
            this.$emit('selectedUser', opcion.personas_id);
          }
          this.filtroActivo = null;
          this.$store.dispatch("lineascredito/getLineas", {
            tipo: this.tipoLineas ? 1 : 0,
            filtro: this.tagsFiltros
          });
        }
      }
      this.$store.dispatch("lineascredito/setFiltros", this.tagsFiltros);
    },
    /*
      @pagina(Integer): número de página para hacer request
    */
    paginacionLinea(pagina) {
      this.$store
        .dispatch("lineascredito/getLineas", {
          tipo: this.tipo_disposicion,
          filtro: this.tagsFiltros,
          pagina: pagina
        })
        .then(() => {
          this.$emit("togglecheck", false);
        });
    },
    /*
      @opcion(Object): filtro a incluir
    */
    setFiltro(opcion) {
      let id = null;
      let nombre = null;
      let relation = null;
      switch (this.filtroActivo) {
        case "status_id":
          id = this.getLineaStatus.find(x => x.nombre == opcion).id;
          nombre = opcion;
          break;
        case "personas_id":
          id = opcion.id;
          nombre = opcion.alias;
          break;
        case "persona2_id":
          id = opcion.personas_id;
          nombre = opcion.alias;
          relation = "relaciones";
          break;
        case "id":
          id = opcion;
          nombre = opcion;
          break;
        case "productos_financieros_id":
          id = opcion.id;
          nombre = opcion.alias;
          break;
      }
      return {
        filtro_id: this.filtroActivo,
        filtro: this.filtros[this.filtroActivo],
        id: id,
        nombre: nombre,
        relacion: relation
      };
    },
    /*
      @filtro(Object): comprueba si el filtro ya se encuentra en uso
    */
    checkRepetido(filtro) {
      let repetido = this.tagsFiltros.filter(filtro_temp => {
        return (
          Object.entries(filtro_temp).toString() ===
          Object.entries(filtro).toString()
        );
      });
      if (repetido.length > 0) {
        this.filtroActivo = null;
        this.notification(
          "warning",
          "Filtro en uso",
          "El filtro que quieres agregar ya se encuentra en uso"
        );
        return true;
      }
      return false;
    },
    /*
      Elimina el filtro por su indice en el arreglo
      @index(Integer): indice del arreglo
    */
    removeFilter(index) {
      this.tagsFiltros.splice(index, 1);
      this.$store.dispatch("lineascredito/getLineas", {
        tipo: this.tipoLineas ? 1 : 0,
        filtro: this.tagsFiltros
      });
      this.$store.dispatch("lineascredito/setFiltros", this.tagsFiltros);
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getClientes",
      "getProductosFinancieros",
      "getLineaStatus"
    ]),
    nombreFiltro() {
      for (var [key, value] of Object.entries(this.filtros)) {
        if (key == this.filtroActivo) {
          return value;
        }
      }
      return "";
    },
    filteredOpciones() {
      switch (this.filtroActivo) {
        case "status_id":
          return this.lineaStatus;
        case "personas_id":
        case "productos_financieros_id":
        case "persona2_id":
          if (! Array.isArray(this.opciones)) {
            return this.opciones || [];
          }

          return this.opciones.filter(
            option => {
              if (! option.alias) return false;

              return option.alias
                .toString()
                .toLowerCase()
                .indexOf(this.opcionActiva.toLowerCase()) >= 0
            }
          );
        case "id":
          return this.opciones.filter(
            option => option.id.toString().indexOf(this.opcionActiva) >= 0
          );
      }
      return [];
    }
  },
  watch: {
    tipoLineas() {
      if (this.tagsFiltros.length > 0) {
        this.$store.dispatch("lineascredito/getLineas", {
          tipo: this.tipoLineas ? 1 : 0,
          filtro: this.tagsFiltros
        });
      }
    },
    filtroActivo: function(val) {
      this.opcionActiva = "";
      this.autocomplete = true;
      this.sinFiltro = false;
      this.id = false;
      switch (val) {
        case "todas":
          this.sinFiltro = true;
          this.$store.dispatch("lineascredito/clearLineasFiltradas");
          this.tagsFiltros = [];
          break;
        case "status_id":
          this.field = "nombre";
          this.grouped = true;
          this.opciones = this.lineaStatus;
          this.autocomplete = false;
          break;
        case "personas_id":
          this.field = "alias";
          this.opciones = this.ejecutivos;
          break;
        case "persona2_id":
          this.field = "alias";
          this.opciones = this.getClientes;
          break;
        case "id":
          this.id = true;
          this.field = "id";
          break;
        case "productos_financieros_id":
          this.field = "alias";
          this.opciones = this.getProductosFinancieros;
          break;
      }
    },
    pagina: function(pagina) {
      if (this.tagsFiltros.length > 0) {
        this.paginacionLinea(pagina);
      } else {
        this.$emit("change-page", pagina);
      }
    },
    opcionActiva: debounce(function(val) {
      if (this.id) {
        this.cargarLineas(val);
      }
    }, 500)
  },
  mounted() {
    if (this.getClientes == null) {
      this.$store.dispatch("catalogos/getClientes");
    }
    if (this.getProductosFinancieros.length == 0) {
      this.$store.dispatch("catalogos/getProductosFinancieros");
    }
    if (this.getLineaStatus == null) {
      this.$store.dispatch("catalogos/getLineaStatus");
    }
  }
};
</script>
