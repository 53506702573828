<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0">
      <div class="columns">
        <div class="column text-left flex items-center">
          <p class="text-2xl font-bold">Líneas de Crédito</p>
          <div class="pt-0 mt-0 ml-3">
          <b-field type="is-text">
            <b-select
              placeholder="Selecciona un tipo de línea"
              v-model="pasiva"
            >
              <option :value="false">Activas</option>
              <option :value="true">Pasivas</option>
            </b-select>
          </b-field>
          </div>
          <div class="column pt-0 mt-0 ml-3">
            <Filtros
              :pagina="currentPage"
              :tipo-lineas="pasiva"
              :linea-status="pasos"
              :ejecutivos="ejecutivos"
              :status="active_status"
              @change-page="getLineasByStatusPage"
              @toggleDropdownReestructura="checkFiltroAcreditado"
              @selectedUser="setUserReestructuraRenovacion"
            />
          </div>
        </div>
        <div v-if="filtroAcreditadoActivo" class="column is-2 mr-5">
          <b-dropdown
            aria-role="list"
          >
            <template #trigger="{ active }">
              <b-button
                label="Reestructurar líneas"
                type="is-primary"
                :icon-right="active ? 'chevron-up' : 'chevron-down'"
              />
            </template>

            <b-dropdown-item
              @click="openModalReestructuraRenovacion('reestructura')"
              aria-role="listitem"
              class="text-left"
            >
              Reestructurar líneas
            </b-dropdown-item>
            <b-dropdown-item
              @click="openModalReestructuraRenovacion('renovación')"
              aria-role="listitem"
              class="text-left"
            >
              Reenovar líneas
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column text-right is-2">
          <b-button
            tag="router-link"
            :to="{ name: 'crear_linea', params: { pasiva }}"
            :type="pasiva ? 'is-pasivo' : 'is-primary'"
            icon-left="plus"
            expanded
          >
            Crear línea
          </b-button>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-12 p-0" v-if="getMe">
          <bienvenido-card
            :titulo="
              '¡Bienvenido a tu módulo de líneas de crédito,' +
                getMe.alias +
                '!'
            "
            descripcion="Este módulo cuenta con un sistema de clasificación para las líneas de crédito que va desde la prospección hasta la finalización del crédito. Podrás filtrar las líneas por estatus seleccionando uno de los recuadros más abajo y también filtrar por cosas más específicas (por cliente, ejecutivo, folio de línea, etc)."
            titulo-size="text-xl"
            img="/images/linea_credito.svg"
            :pasiva="pasiva"
            img-class="linea-img"
          />
        </div>
        <!-- <div class="column is-3 flex text-left">
          <b-switch
            size="is-medium"
            type="is-pasivo"
            v-model="pasiva"
            passive-type="is-primary"
            >Viendo Cartera {{ pasiva ? "pasiva" : "activa" }}</b-switch
          >
        </div> -->
      </div>
      <div class="columns is-multiline" v-if="getFiltros.length == 0">
        <div class="column is-10 text-left">
          <div
            v-for="(tag, index) in tags"
            :key="index"
            class="px-2 inline-block pb-2 pt-4"
          >
            <b-tag
              class="cursor-pointer tag-clickable"
              @click.native="active_tag = index"
              :class="{ pasivo: pasiva, 'is-active': index == active_tag }"
              >{{ tag }}</b-tag
            >
          </div>
        </div>
        <div class="column is-2 text-right">
          <radio-group
            class="mt-4 hidden md:block"
            :buttons="radio_buttons"
            v-model="view_type"
          />
        </div>
      </div>
      <div class="columns" v-if="getFiltros.length == 0">
        <div class="column is-12 px-0 -mx-4" v-if="!view_type">
          <b-carousel-list
            v-model="active_tag"
            :data="pasos"
            :arrow="true"
            :arrow-hover="false"
            :items-to-list="1"
            :items-to-show="1"
            :repeat="false"
            :indicator="true"
            :has-drag="true"
            :has-opacity="true"
            @switch="val => (active_tag = val)"
          >
            <template slot="item" slot-scope="props">
              <p class="text-lg font-light text-left mb-2 ml-12">
                {{ props.list.group }}
              </p>
              <div class="flex mx-10 columns is-multiline">
                <div
                  class="column is-4-mobile is-3-tablet is-2-desktop"
                  v-for="(card, index) in props.list.children"
                  :key="index"
                >
                  <div
                    class="has-border-radius p-3 pt-2 has-background-white-ter cursor-pointer card-clickable w-full h-full"
                    :class="{
                      pasiva: pasiva,
                      primary: !pasiva,
                      active: active_status == index
                    }"
                    @click="setActiveStatus(index)"
                  >
                    <p class="font-bold text-left h-12 align-middle text-sm">
                      {{ index }}
                    </p>
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-black text-left">
                        {{ card[0] || 0 }}
                      </p>
                      <fa-icon
                        :icon="card[2].name"
                        size="2x"
                        :class="{
                          'has-text-grey': card[2].color == 'grey',
                          'has-text-success': card[2].color == 'success',
                          'has-text-warning': card[2].color == 'warning',
                          'has-text-danger': card[2].color == 'danger'
                        }"
                      />
                    </div>
                    <p class="text-sm text-left">
                      {{ card[1] | currency }} MXN
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </b-carousel-list>
        </div>
        <div class="column is-12" v-else>
          <funnel-chart
            v-show="getResumen.embudo.length > 0"
            :data="getResumen.embudo"
            @click="setActiveStatus"
          />
          <div v-if="getResumen.embudo.length == 0">
            <p>
              No existen suficientes líneas de crédito para construir la gráfica
            </p>
          </div>
        </div>
      </div>
      <transition class="w-full" name="fadeInOut" mode="out-in">
        <div class="columns mt-6">
          <div class="column is-12" v-if="getLineasFiltradas">
            <b-table
              ref="tableContainer"
              :data="getLineasFiltradas.lineas_credito"
              paginated
              backend-pagination
              :total="getLineasFiltradas.total"
              :per-page="getLineasFiltradas.por_pagina"
              @page-change="page => (currentPage = page)"
              aria-next-label="Siguiente Pagina"
              aria-previous-label="Pagina anterior"
              aria-page-label="Pagina"
              aria-current-label="Pagina actual"
              :selected.sync="lineaSelected"
              focusable
              class="cursor-pointer"
            >
              <b-table-column field="id" label="Folio" numeric v-slot="props">{{
                props.row.id
              }}</b-table-column>
              <b-table-column field="pasiva" label="Tipo" v-slot="props">{{
                props.row.pasiva === 0 ? "Activa" : "Pasiva"
              }}</b-table-column>
              <b-table-column
                field="status_linea_directo"
                label="Estatus"
                v-slot="props"
              >
                {{
                  props.row.status_linea_directo
                    ? props.row.status_linea_directo.nombre
                    : "Prospecto"
                }}
              </b-table-column>
              <b-table-column
                field="nombre_acreditado"
                label="Acreditado"
                v-slot="props"
              >
                <span v-if="props.row.relaciones.length > 0">
                  <preview
                    :color="pasiva ? 'has-text-pasivo' : 'has-text-primary'"
                    :border="pasiva ? 'has-border-pasivo' : 'has-border-primary'"
                    v-for="(acreditado, index) in props.row.relaciones.map(
                      x => x.relacionado
                    )"
                    :key="index"
                    :id="acreditado.id"
                    tipo="persona"
                    class="mr-2"
                    >{{ acreditado.nombre_completo.split("|")[0] }}</preview
                  >
                </span>
                <span v-else>
                  <preview
                    :color="pasiva ? 'has-text-pasivo' : 'has-text-primary'"
                    :border="pasiva ? 'has-border-pasivo' : 'has-border-primary'"
                    :id="props.row.fuente_de_fondeo.id"
                    tipo="persona"
                    class="mr-2"
                    >{{ props.row.fuente_de_fondeo.nombre_completo.split("|")[0] }}</preview
                  >
                </span>
              </b-table-column>
              <b-table-column
                field="ejecutivo"
                label="Ejecutivo"
                v-slot="props"
              >
                <preview
                  v-if="props.row.ejecutivo"
                  :id="props.row.ejecutivo.id"
                  tipo="persona"
                  :color="pasiva ? 'has-text-pasivo' : 'has-text-primary'"
                  :border="pasiva ? 'has-border-pasivo' : 'has-border-primary'"
                  >{{
                    props.row.ejecutivo.nombre_completo.split("|")[0]
                  }}</preview
                >
                <span v-else>N/A</span>
              </b-table-column>
              <b-table-column
                field="producto"
                label="Producto financiero"
                v-slot="props"
                >{{ props.row.producto_financiero.alias }}</b-table-column
              >
              <b-table-column
                field="capital"
                label="Capital"
                class="text-right"
                header-class="text-right"
                v-slot="props"
                >{{
                  props.row.capital_autorizado | currency | hp
                }}</b-table-column
              >
              <b-table-column
                field="capital_disponible"
                label="Capital disponible"
                class="text-right"
                header-class="text-right"
                v-slot="props"
                >{{
                  props.row.status_linea_directo.nombre == "Finalizado"
                    ? 0
                    : props.row.capital_disponible | currency | hp
                }}</b-table-column
              >
              <template slot="empty">
                <section class="section">
                  <empty-state
                    type="svg"
                    vertical
                    imagen="/images/credit_card.svg"
                    titulo="No tienes líneas de crédito"
                    subtitulo="Intenta con otro filtro o estatus"
                  />
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </transition>
      <portal to="modalContainer">
      <b-modal
        v-if="is_modal_reestructura_renovacion_open"
        :active.sync="is_modal_reestructura_renovacion_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-reestructura-renovacion
          :tipo="tipo_modal"
          :pasiva="pasiva"
          :lineas="lineasIds"
          :clienteId="userReestructuraRenovacion"
        />
      </b-modal>
    </portal>
    </div>
  </Layout>
</template>

<script>
import BienvenidoCard from "@/components/cards/BienvenidoCard";
import Filtros from "@/components/generals/filtros/FiltrosLinea";
import FunnelChart from "@/components/charts/FunnelChart";
import EmptyState from "@/components/generals/EmptyState";
import RadioGroup from "@/components/generals/RadioGroup";
import ModalReestructuraRenovacion from '@/components/modals/ModalReestructuraRenovacion.vue';
import { mapGetters } from "vuex";

export default {
  name: "LineasCredito",
  components: {
    BienvenidoCard,
    Filtros,
    FunnelChart,
    EmptyState,
    RadioGroup,
    ModalReestructuraRenovacion,
  },
  data() {
    return {
      pasiva: false,
      tags: [
        "Promoción",
        "Crédito",
        "Instancias",
        "Proceso jurídico",
        "En mesa",
        "Miscelánea",
        "En tratamiento"
      ],
      active_tag: 0,
      active_status: null,
      currentPage: 1,
      lineaSelected: null,
      view_type: false,
      radio_buttons: [
        {
          icon: "th-list",
          value: false
        },
        {
          icon: "funnel-dollar",
          value: true
        }
      ],
      filtroAcreditadoActivo: false,
      tipo_modal: null,
      is_modal_reestructura_renovacion_open: false,
      userReestructuraRenovacion: null,
    };
  },
  computed: {
    ...mapGetters(["getMe"]),
    ...mapGetters("lineascredito", [
      "getResumen",
      "getLineasFiltradas",
      "getLineas",
      "getFiltros"
    ]),
    ...mapGetters("catalogos", ["getLineaStatus"]),
    columns() {
      return [
        {
          field: "id",
          label: "Folio",
          headerClass: "pl-0 text-sm"
        },
        {
          field: "status_linea_directo.nombre",
          label: "Estatus"
        },
        {
          field: "status_linea_directo.relaciones.relacionado.nombre_completo",
          label: "Acreditado"
        }
      ];
    },
    pasos() {
      if (!this.getResumen) {
        return [];
      }
      let grupos = [
        {
          id: "promocion",
          group: "Promoción"
        },
        {
          id: "credito",
          group: "Crédito"
        },
        {
          id: "instancias",
          group: "Instancias"
        },
        {
          id: "juridico",
          group: "Proceso jurídico"
        },
        {
          id: "mesa",
          group: "En mesa"
        },
        {
          id: "misc",
          group: "Miscelánea"
        },
        {
          id: "tratamiento",
          group: "En tratamiento"
        }
      ];
      Object.keys(this.getResumen).filter(grupokey => {
        let match = grupos.find(grupo => grupo.id == grupokey);
        if (match) {
          match.children = this.getResumen[grupokey];
          return match;
        }
      });
      return grupos;
    },
    ejecutivos() {
      if (this.getResumen) {
        return this.getResumen.ejecutivosEnLineas;
      }
      return [];
    },
    lineasIds() {
      return this.getLineasFiltradas.lineas_credito.map(linea => linea.id);
    }
  },
  watch: {
    active_status(val) {
      if (val) {
        this.getLineasByStatus(val, 1);
      }
    },
    pasiva(val) {
      this.$store.dispatch("lineascredito/resumen", val ? 1 : 0);
      if (this.active_status) {
        this.getLineasByStatus(this.active_status, 1);
      }
    },
    lineaSelected(val) {
      this.$router.push({
        name: "detalle_linea",
        params: { idLinea: val.id }
      });
    }
  },
  methods: {
    getLineasByStatus(status_nombre, pagina) {
      this.$store.dispatch("lineascredito/getLineasByStatus", {
        tipo: this.pasiva ? 1 : 0,
        status: status_nombre
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .split(" ")
          .join("_")
          .toLowerCase(),
        pagina: pagina
      });
    },
    getLineasByStatusPage(page) {
      this.getLineasByStatus(this.active_status, page);
    },
    setActiveStatus(index) {
      this.active_status = index;
    },
    checkFiltroAcreditado(value) {
      this.filtroAcreditadoActivo = value;
    },
    openModalReestructuraRenovacion(tipo) {
      this.tipo_modal = tipo;
      this.is_modal_reestructura_renovacion_open = true;
    },
    setUserReestructuraRenovacion(id) {
      this.userReestructuraRenovacion = id;
    }
  },
  mounted() {
    this.$store.dispatch("lineascredito/resumen", 0);
    if (this.getLineaStatus == null) {
      this.$store.dispatch("catalogos/getLineaStatus");
    }
    this.setActiveStatus("Prospecto");
  }
};
</script>

<style lang="scss">
.linea-img {
  transform: scale(2.5);
  top: -2.5rem;
}
.carousel-list.has-shadow {
  box-shadow: none;
}
</style>
